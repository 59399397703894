/* eslint-disable */
export default [
  {
    header: 'Cronograma',
    icon: 'FolderIcon',
    id: 'cronograma',
    title: 'Cronograma',
    route: 'cronograma',
  },
  {
    header: 'Cronograma Agregar',
    icon: 'FolderIcon',
    id: 'cronograma-registro',
    title: 'Cronograma Agregar',
    route: 'cronograma-registro',
    hide: true,
  },
  {
    header: 'Cronograma Editar',
    icon: 'FolderIcon',
    id: 'cronograma-editar',
    title: 'Cronograma Editar',
    route: 'cronograma-editar',
    hide: true,
  },
  {
    header: 'Actividad',
    icon: 'FolderIcon',
    id: 'actividad',
    title: 'Actividad',
    route: 'actividad',
  },
  {
    header: 'Actividad Agregar',
    icon: 'FolderIcon',
    id: 'actividad-registro',
    title: 'Actividad Agregar',
    route: 'actividad-registro',
    hide: true,
  },
  {
    header: 'Actividad Editar',
    icon: 'FolderIcon',
    id: 'actividad-editar',
    title: 'Actividad Editar',
    route: 'actividad-editar',
    hide: true,
  },
  {
    icon: 'StopCircleIcon',
    id: 'aprobaciones',
    title: 'Aprobaciones Servicios',
    route: 'aprobaciones'
  },  
  {
    icon: 'StopCircleIcon',
    id: 'aprobaciones-actividad',
    title: 'Aprobaciones Actividades',
    route: 'aprobaciones-actividad'
  },  
  {
    header: 'Registros',
    icon: 'FolderIcon',
    id: 'registros',
    title: 'Registros',
    route: 'registros',
  },
  {
    header: 'Registros Lista',
    icon: 'FolderIcon',
    id: 'registros-aprobar',
    title: 'Registros',
    route: 'registros-aprobar',
  },
  {
    header: 'Registros Actividad',
    icon: 'FolderIcon',
    id: 'registros-actividad',
    title: 'Registros Actividad',
    route: 'registros-actividad',
  },
  {
    header: 'Registros Actividad Lista',
    icon: 'FolderIcon',
    id: 'registros-actividad-aprobar',
    title: 'Registros Actividad',
    route: 'registros-actividad-aprobar',
  },
  {
    header: 'Usuarios',
    icon: 'FolderIcon',
    id: 'usuarios',
    title: 'Usuarios',
    route: 'usuarios',
  },
  {
    header: 'Importar Usuarios',
    icon: 'FolderIcon',
    id: 'usuarios-importar',
    title: 'Usuarios',
    route: 'usuarios-importar',
  },
  {
    header: 'Importar Servicios',
    icon: 'FolderIcon',
    id: 'importar',
    title: 'Importar Servicios',
    route: 'importar',
  },
  {
    header: 'Importar Actividades',
    icon: 'FolderIcon',
    id: 'importar-actividad',
    title: 'Importar Actividades',
    route: 'importar-actividad',
  },
  {
    header: 'Perfil',
    icon: 'UserIcon',
    id: 'perfil',
    title: 'Perfil',
    route: 'perfil',
  },
  {
    header: 'Plantilla Servicio',
    icon: 'FolderIcon',
    id: 'plantilla',
    title: 'Plantilla Servicio',
    route: 'plantilla',
  },
  {
    header: 'Plantilla Servicio Agregar',
    icon: 'FolderIcon',
    id: 'plantilla-registro',
    title: 'Plantilla Servicio Agregar',
    route: 'plantilla-registro',
    hide: true,
  },
  {
    header: 'Plantilla Servicio Editar',
    icon: 'FolderIcon',
    id: 'plantilla-editar',
    title: 'Plantilla Servicio Editar',
    route: 'plantilla-editar',
    hide: true,
  },
  {
    header: 'Plantilla Actividad',
    icon: 'FolderIcon',
    id: 'plantilla-actividad',
    title: 'Plantilla Actividad',
    route: 'plantilla-actividad',
  },
  {
    header: 'Plantilla Actividad Agregar',
    icon: 'FolderIcon',
    id: 'plantilla-actividad-registro',
    title: 'Plantilla Actividad Agregar',
    route: 'plantilla-actividad-registro',
    hide: true,
  },
  {
    header: 'Plantilla Actividad Editar',
    icon: 'FolderIcon',
    id: 'plantilla-actividad-editar',
    title: 'Plantilla Actividad Editar',
    route: 'plantilla-actividad-editar',
    hide: true,
  },
  {
    header: 'Plantilla Operativo',
    icon: 'FolderIcon',
    id: 'plantilla-operativo',
    title: 'Plantilla Operativo',
    route: 'plantilla-operativo',
  },
  {
    header: 'Plantilla Operativo Agregar',
    icon: 'FolderIcon',
    id: 'plantilla-operativo-registro',
    title: 'Plantilla Operativo Agregar',
    route: 'plantilla-operativo-registro',
    hide: true,
  },
  {
    header: 'Plantilla Operativo Editar',
    icon: 'FolderIcon',
    id: 'plantilla-operativo-editar',
    title: 'Plantilla Operativo Editar',
    route: 'plantilla-operativo-editar',
    hide: true,
  },
  {
    header: 'Operativo',
    icon: 'FolderIcon',
    id: 'operativo',
    title: 'Mis Formularios',
    route: 'operativo',
  },
  {
    header: 'Operativo Agregar',
    icon: 'FolderIcon',
    id: 'operativo-registro',
    title: 'Operativo Agregar',
    route: 'operativo-registro',
    hide: true,
  },
  {
    header: 'Operativo Editar',
    icon: 'FolderIcon',
    id: 'operativo-editar',
    title: 'Operativo Editar',
    route: 'operativo-editar',
    hide: true,
  },
  {
    icon: 'StopCircleIcon',
    id: 'aprobaciones-operativo',
    title: 'Aprobaciones Operativos',
    route: 'aprobaciones-operativo'
  },  
  {
    header: 'Registros Operativo',
    icon: 'FolderIcon',
    id: 'registros-operativo',
    title: 'Registros Operativo',
    route: 'registros-operativo',
  },
  {
    header: 'Registros Operativo Lista',
    icon: 'FolderIcon',
    id: 'registros-operativo-aprobar',
    title: 'Registros Operativo',
    route: 'registros-operativo-aprobar',
  },
  {
    header: 'Formularios',
    icon: 'FolderIcon',
    id: 'formularios',
    title: 'Formularios',
    route: 'formularios',
    hide: true,
  },
  {
    header: 'Proyectos',
    icon: 'FolderIcon',
    id: 'proyectos',
    title: 'Proyectos',
    route: 'proyectos',
    hide: true,
  },
  {
    header: 'Sedes',
    icon: 'FolderIcon',
    id: 'sedes',
    title: 'Sedes',
    route: 'sedes',
    hide: true,
  },
  {
    header: 'Grupos',
    icon: 'FolderIcon',
    id: 'grupos',
    title: 'Grupos',
    route: 'grupos',
    hide: true,
  },
  {
    header: 'Seleccionar Proyecto',
    icon: 'FolderIcon',
    id: 'seleccionar-proyecto',
    title: 'Seleccionar Proyecto',
    route: 'seleccionar-proyecto',
    hide: true,
  },
]
